@charset 'UTF-8';

$base: #333 !default;
$primary: #ffae00 !default;
$secondary: #f0841a !default;
$tertiary: #f29787 !default;
$quaternary: #fdefcd !default;
$quinary: #fbaa31 !default;
$senary: #ffd08a !default;
$septenary: #006a76 !default;
$octonary: #fafafa !default;
$novenary: #e0005d !default;
$decenary: #94259e !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-dark: #333 !default;
$black: #000 !default;
$white: #fff !default;

$grays: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "gray-dark": $gray-dark,
  "black": $black,
  "white": $white,
) !default;

$primary-50: #eaf6f8 !default;
$primary-100: #4ecae6 !default;
$primary-200: #1daccc !default;
$primary-300: #1996b3 !default;
$primary-400: #158199 !default;
$primary-500: #14788f !default;
$primary-600: #11697d !default;
$primary-700: #0f5a6b !default;
$primary-800: #0b4552 !default;
$primary-900: #072f38 !default;
$primary-on-high: rgba(29, 172, 204, 0) !default;
$primary-on-medium: rgba(29, 172, 204, 0.26) !default;
$primary-on-disabled: rgba(29, 172, 204, 0.62) !default;

$primarys: (
  "primary-50": $primary-50,
  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,
  "primary-600": $primary-600,
  "primary-700": $primary-700,
  "primary-800": $primary-800,
  "primary-900": $primary-900,
  "primary-on-high": $primary-on-high,
  "primary-on-medium": $primary-on-medium,
  "primary-on-disabled": $primary-on-disabled,
) !default;

$secondary-50: #eaf6ff !default;
$secondary-100: #36abff !default;
$secondary-200: #008aed !default;
$secondary-300: #0076c9 !default;
$secondary-400: #0062a8 !default;
$secondary-500: #0068a8 !default;
$secondary-600: #005e99 !default;
$secondary-700: #004f80 !default;
$secondary-800: #00395c !default;
$secondary-900: #00243b !default;
$secondary-on-high: rgba(0, 57, 92, 0) !default;
$secondary-on-medium: rgba(0, 57, 92, 0.26) !default;
$secondary-on-disabled: rgba(0, 57, 92, 0.62) !default;

$secondarys: (
  "secondary-50": $secondary-50,
  "secondary-100": $secondary-100,
  "secondary-200": $secondary-200,
  "secondary-300": $secondary-300,
  "secondary-400": $secondary-400,
  "secondary-500": $secondary-500,
  "secondary-600": $secondary-600,
  "secondary-700": $secondary-700,
  "secondary-800": $secondary-800,
  "secondary-900": $secondary-900,
  "secondary-on-high": $secondary-on-high,
  "secondary-on-medium": $secondary-on-medium,
  "secondary-on-disabled": $secondary-on-disabled,
) !default;

// custom colors
$border-color: #ddd;
$light-gray: #f6f6f6;
$light-green: #f4fcfc;
$light-pink: #fff5f0;
$light-red: #fff4f4;
$gradation: linear-gradient(180deg, $secondary-300, $primary);
$important: #6cb400;
$success: #518207;
$warning: #9f8200;
$danger: #d50000;

$theme-colors: (
  "base": $base,
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $primary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "senary": $senary,
  "septenary": $septenary,
  "octonary": $octonary,
  "novenary": $novenary,
  "decenary": $decenary,
) !default;

$red: #ff3c02 !default;
$yellow: #fff350 !default;
$orange: #f18f2c !default;
$green: #63a764 !default;
$blue: #005fcc !default;
$pink: #ff0066 !default;
$purple: #6f42c1 !default;
$cyan: #17a2b8 !default;
$gray: #5c5d5e !default;

$commons-colors: (
  "red": $red,
  "yellow": $yellow,
  "orange": $orange,
  "green": $green,
  "blue": $blue,
  "pink": $pink,
  "purple": $purple,
  "cyan": $cyan,
  "light-gray": $light-gray,
  "light-green": $light-green,
  "light-pink": $light-pink,
  "gray": $gray,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
) !default;

// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$basic-colors: map-merge($grays, $commons-colors);

// テーマカラーを含めたすべての配列
$all-colors: map-merge($theme-colors, $basic-colors);
