@charset 'UTF-8';
@use "../global/" as *;

// hero
.top-hero {
  position: relative;
}
.top-hero-swiper {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    @include media(lg) {
      height: 96vh;
    }
    &.img01 {
      @include media(lg) {
        object-position: top;
      }
    }
  }
}
.top-hero-text {
  width: 100%;
  font-family: "Shippori Mincho", serif;
  font-size: 8.6vw;
  font-weight: 500;
  color: $white;
  text-align: center;
  text-shadow: 0 3px 20px rgba($color: $black, $alpha: 0.35);
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  z-index: 1;
  &::first-line {
    line-height: 1;
  }
  @include media(lg) {
    left: 8vw;
    text-align: left;
    font-size: 6.6rem;
    transform: translateY(-70%);
  }
}

.top-hero-saigai {
  padding: 1.5rem 2rem;
  color: $white;
  background-color: #43c3b4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  right: 19.6rem;
  bottom: 1.6rem;
  z-index: 1;
  @media (hover: hover) {
    &:hover {
      color: $white;
      opacity: 0.5;
    }
  }
  @include media(lg) {
    width: 200px;
    padding: 2.5rem 1.6rem;
    right: 25.2rem;
    bottom: 18.8rem;
  }
  &__icon {
    font-size: 3.8rem;
    @include media(lg) {
      font-size: 4.8rem;
    }
  }
  &__text {
    width: 8rem;
    display: block;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.5;
    padding-left: 0.5rem;
    letter-spacing: 0.05em;
    @include media(lg) {
      width: auto;
      font-size: 1.6rem;
      padding-left: 1.2rem;
      flex: 1;
    }
    &::first-line {
      font-size: 1rem;
      @include media(lg) {
        font-size: 1.2rem;
      }
    }
  }
}
.top-hero-recruit {
  padding: 1.5rem 2rem;
  color: $white;
  background-color: $tertiary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  z-index: 1;
  @media (hover: hover) {
    &:hover {
      color: $white;
      opacity: 0.5;
    }
  }
  @include media(lg) {
    width: 200px;
    padding: 2.5rem 1.6rem;
    right: 2.2rem;
    bottom: 18.8rem;
  }
  &__icon {
    font-size: 3.8rem;
    @include media(lg) {
      font-size: 4.8rem;
    }
  }
  &__text {
    width: 8rem;
    display: block;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5;
    padding-left: 0.5rem;
    letter-spacing: 0.05em;
    @include media(lg) {
      width: auto;
      font-size: 2rem;
      padding-left: 1.2rem;
      flex: 1;
    }
    &::first-line {
      font-size: 1rem;
      @include media(lg) {
        font-size: 1.2rem;
      }
    }
  }
}

// consultation
.top-consultation {
  @include media(lg) {
    padding: 3.2rem 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 155px;
    background-color: rgba($color: $quinary, $alpha: 0.8);
    z-index: 1;
  }
  &-wrap {
    @include media(lg) {
      height: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-time {
    @include media(lg) {
      width: 42%;
      max-width: 506px;
      padding: 3.6rem 5rem;
      border-radius: 12px;
      transform: translateY(-20px);
      th {
        width: 7em;
        padding: 1.2rem 0;
      }
    }
  }
  &-info {
    display: none;
    color: $white;
    @include media(lg) {
      display: flex;
      // margin-right: 5.3rem;
      letter-spacing: 0;
    }
  }
  &-inquiry {
    margin-right: 2.95rem;
    &__tel {
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      display: table;
      border-bottom: 1px solid $white;
      dt,
      dd {
        display: table-cell;
        font-size: 1.5rem;
        line-height: 1.4;
      }
      dt {
        width: 6em;
        padding-right: 2.15rem;
        border-right: 1px solid $white;
        box-sizing: content-box;
      }
      dd {
        padding-left: 2.05rem;
        padding-right: 2.9rem;
        font-weight: bold;
        font-family: $base-font-family-en;
        font-size: 1.8rem;
        vertical-align: bottom;
        line-height: 1;
        a {
          color: $white;
          font-size: 3.1rem;
          padding-left: 1rem;
        }
      }
    }
    &__address {
      letter-spacing: 0.05em;
    }
  }
  &-reserv {
    &__btn {
      width: 132px;
      display: block;
      padding: 1.3rem 3.3rem;
      background-color: $white;
      border-radius: 6px;
      color: $secondary;
      text-align: center;
      i {
        font-size: 2.7rem;
      }
      @media (hover: hover) {
        &:hover {
          color: $secondary;
          opacity: 0.5;
        }
      }
    }
  }
}

// topics
.top-topics {
  margin: 2.2rem auto 3.7rem;
  padding: 0 2.4rem;
  background-color: $quaternary;
  @include media(lg) {
    margin: 14rem auto 5.5rem;
    padding: 0 7rem;
    background-color: transparent;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    @include media(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 4.2rem;
      grid-row-gap: 0;
    }
  }
  &-link {
    height: 100%;
    display: block;
    padding: 1.6rem 2rem;
    background-color: $senary;
    text-align: center;
    color: $secondary;
    border-radius: 6px;
    letter-spacing: 0.05em;
    line-height: 1;
    @include media(lg) {
      padding: 3.3rem 3rem 4.9rem;
    }
    &__icon {
      max-width: 84px;
      margin: 0 auto 1rem;
      @include media(lg) {
        max-width: 136px;
        margin-bottom: 3rem;
      }
    }
    &__text {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.2rem;
      @include media(lg) {
        font-size: 2rem;
        margin-bottom: 2.6rem;
      }
    }
    &__more {
      font-size: 1.2rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media(lg) {
        font-size: 1.4rem;
      }
      &::after {
        content: "";
        @include setSvgIcon($chevron-right, $secondary, 2.2rem);
        vertical-align: text-top;
      }
    }
    &:hover {
      @include media(lg) {
        background-color: $secondary;
        color: #fff;
        .top-topics-link__more::after {
          @include media(lg) {
            @include setSvgIcon($chevron-right, #fff, 2.2rem);
          }
        }
      }
    }
  }
}

// news
.top-news {
  padding: 5rem 0 7rem;
  background: radial-gradient(
      circle at left 6px bottom 45px,
      $quaternary calc(258px / 2 - 1px),
      transparent calc(258px / 2)
    )
    no-repeat;
  @include media(lg) {
    padding-bottom: 15.5rem;
    background: radial-gradient(
        circle at left 6px top calc(546px / 2),
        $quaternary calc(546px / 2 - 1px),
        transparent calc(546px / 2)
      )
      no-repeat;
  }
  &-inner {
    @include media(lg) {
      display: flex;
    }
  }
  &-title {
    margin-bottom: 1.5rem;
    @include media(lg) {
      padding-right: 14vw;
    }
  }
  &-list {
    display: table;
    margin: 0;
    padding: 0;
    width: 100%;
    @include media(lg) {
      margin-top: 4.4rem;
      flex: 1;
    }
  }
}

// photo
.top-photo {
  img {
    width: 100%;
    max-height: 511px;
    object-fit: cover;
  }
}

// info
.top-info {
  padding: 5.2rem 0 0;
  background: radial-gradient(
      circle at right 0 top 24px,
      $quaternary calc(258px / 2 - 1px),
      transparent calc(258px / 2)
    )
    no-repeat;
  @include media(lg) {
    margin-bottom: 9.6rem;
    padding: 11rem 0 0;
    background: radial-gradient(
        circle at right 100px top 44px,
        $quaternary calc(654px / 2 - 1px),
        transparent calc(654px / 2)
      )
      no-repeat;
  }
  .content-title {
    &--en {
      margin-bottom: 3.5rem;
      letter-spacing: 0.08em;
      @include media(lg) {
        margin-bottom: 6rem;
      }
    }
  }
  &-wrap {
    @include media(lg) {
      display: flex;
      flex-wrap: wrap;
      background: radial-gradient(
          circle at left 50px bottom calc(654px / 2 + 40px),
          $quaternary calc(654px / 2 - 1px),
          transparent calc(654px / 2)
        )
        no-repeat;
    }
  }
  &-photo {
    margin-right: 2.2rem;
    @include media(lg) {
      width: calc(50% - 11.3rem);
      margin-right: 11.3rem;
    }
    img {
      border-radius: 0 2rem 2rem 0;
      @include media(lg) {
        height: auto;
        object-fit: cover;
        border-radius: 0 4rem 4rem 0;
        object-position: right;
      }
    }
  }
}

// about
.top-about {
  padding-top: 6.5rem;
  background: radial-gradient(
      circle at left 6px top 45px,
      $quaternary calc(258px / 2 - 1px),
      transparent calc(258px / 2)
    )
    no-repeat;
  @include media(lg) {
    width: 50%;
    padding-top: 2.4rem;
    background: none;
  }
  .content-inner {
    @include media(lg) {
      padding: 0;
    }
  }
  &__title {
    @include media(lg) {
      margin-bottom: 6.2rem;
    }
  }
  dl.dl-list {
    @include media(md) {
      display: block;
    }
    dt {
      font-size: 1.6rem;
      padding-bottom: 1.8rem;
      &:nth-of-type(n + 2) {
        margin-top: 2.8rem;
      }
      @include media(md) {
        font-size: 1.8rem;
        border: none;
        padding-bottom: 1rem;
        &:nth-of-type(n + 2) {
          margin-top: 2.6rem;
        }
      }
    }
    dd {
      border: none;
      padding-bottom: 0;
      @include media(md) {
        padding-left: 0;
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
      }
    }
    .txt-tel {
      margin: 0.6rem auto;
      @include media(lg) {
        margin: 0;
        padding-bottom: 0.5rem;
        letter-spacing: 0.02em;
      }
    }
  }
  .consultation-time {
    margin-top: 1.6rem;
    padding: 0;
    box-shadow: none;
    color: $base;
    @include media(lg) {
      margin-top: 0.6rem;
    }
    &__table {
      tr {
        border-bottom-color: $border-color;
      }
      @include media(lg) {
        th,
        td {
          padding: 1.15rem 0;
        }
        th {
          padding-left: 2px;
        }
      }
    }
    &__closed {
      @include media(lg) {
        margin-top: 1.2rem;
      }
    }
  }
}

// access
.top-access {
  padding-top: 2.4rem;
  padding-bottom: 6.6rem;
  background: radial-gradient(
      circle at right 0 bottom calc(258px / 2),
      $quaternary calc(258px / 2 - 1px),
      transparent calc(258px / 2)
    )
    no-repeat;
  @include media(lg) {
    padding-bottom: 17.4rem;
    background: radial-gradient(
        circle at right 75px bottom calc(654px / 2 + 74px),
        $quaternary calc(654px / 2 - 1px),
        transparent calc(654px / 2)
      )
      no-repeat;
  }
  iframe {
    width: 100%;
    margin-bottom: 1.4rem;
    aspect-ratio: 3/3.22; //アスペクト比（縦横比）を指定
    border-radius: 12px;
    @include media(lg) {
      aspect-ratio: 16/6.73;
    }
  }
  &-foot {
    @include media(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .btn-wrap {
      @include media(lg) {
        margin-top: 0;
      }
      .btn {
        min-width: 184px;
        padding: 1rem;
      }
    }
  }
  .content-title--en {
    margin-bottom: 2rem;
    @include media(lg) {
      margin-bottom: 6rem;
    }
  }
}

// banner
.top-banner {
  padding: 3.8rem 0 3.3rem;
  background-color: $octonary;
  @include media(lg) {
    padding: 8.6rem 0;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    @include media(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      width: 1000px;
      margin: 0 auto;
    }
  }
}
