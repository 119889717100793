@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Raleway:wght@700&family=Roboto:wght@500&family=Shippori+Mincho:wght@500&display=swap");
/* テンプレートアイコン
====================================================*/
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
}
@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
}
.top-hero {
  position: relative;
}

.top-hero-swiper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .top-hero-swiper img {
    height: 96vh;
  }
}
@media screen and (min-width: 1024px) {
  .top-hero-swiper img.img01 {
    object-position: top;
  }
}

.top-hero-text {
  width: 100%;
  font-family: "Shippori Mincho", serif;
  font-size: 8.6vw;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 20px rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  z-index: 1;
}
.top-hero-text::first-line {
  line-height: 1;
}
@media screen and (min-width: 1024px) {
  .top-hero-text {
    left: 8vw;
    text-align: left;
    font-size: 6.6rem;
    transform: translateY(-70%);
  }
}

.top-hero-saigai {
  padding: 1.5rem 2rem;
  color: #fff;
  background-color: #43c3b4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  right: 19.6rem;
  bottom: 1.6rem;
  z-index: 1;
}
@media (hover: hover) {
  .top-hero-saigai:hover {
    color: #fff;
    opacity: 0.5;
  }
}
@media screen and (min-width: 1024px) {
  .top-hero-saigai {
    width: 200px;
    padding: 2.5rem 1.6rem;
    right: 25.2rem;
    bottom: 18.8rem;
  }
}
.top-hero-saigai__icon {
  font-size: 3.8rem;
}
@media screen and (min-width: 1024px) {
  .top-hero-saigai__icon {
    font-size: 4.8rem;
  }
}
.top-hero-saigai__text {
  width: 8rem;
  display: block;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.5;
  padding-left: 0.5rem;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .top-hero-saigai__text {
    width: auto;
    font-size: 1.6rem;
    padding-left: 1.2rem;
    flex: 1;
  }
}
.top-hero-saigai__text::first-line {
  font-size: 1rem;
}
@media screen and (min-width: 1024px) {
  .top-hero-saigai__text::first-line {
    font-size: 1.2rem;
  }
}

.top-hero-recruit {
  padding: 1.5rem 2rem;
  color: #fff;
  background-color: #f29787;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  right: 1.6rem;
  bottom: 1.6rem;
  z-index: 1;
}
@media (hover: hover) {
  .top-hero-recruit:hover {
    color: #fff;
    opacity: 0.5;
  }
}
@media screen and (min-width: 1024px) {
  .top-hero-recruit {
    width: 200px;
    padding: 2.5rem 1.6rem;
    right: 2.2rem;
    bottom: 18.8rem;
  }
}
.top-hero-recruit__icon {
  font-size: 3.8rem;
}
@media screen and (min-width: 1024px) {
  .top-hero-recruit__icon {
    font-size: 4.8rem;
  }
}
.top-hero-recruit__text {
  width: 8rem;
  display: block;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding-left: 0.5rem;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .top-hero-recruit__text {
    width: auto;
    font-size: 2rem;
    padding-left: 1.2rem;
    flex: 1;
  }
}
.top-hero-recruit__text::first-line {
  font-size: 1rem;
}
@media screen and (min-width: 1024px) {
  .top-hero-recruit__text::first-line {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .top-consultation {
    padding: 3.2rem 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 155px;
    background-color: rgba(251, 170, 49, 0.8);
    z-index: 1;
  }
}
@media screen and (min-width: 1024px) {
  .top-consultation-wrap {
    height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .top-consultation-time {
    width: 42%;
    max-width: 506px;
    padding: 3.6rem 5rem;
    border-radius: 12px;
    transform: translateY(-20px);
  }
  .top-consultation-time th {
    width: 7em;
    padding: 1.2rem 0;
  }
}
.top-consultation-info {
  display: none;
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .top-consultation-info {
    display: flex;
    letter-spacing: 0;
  }
}
.top-consultation-inquiry {
  margin-right: 2.95rem;
}
.top-consultation-inquiry__tel {
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: table;
  border-bottom: 1px solid #fff;
}
.top-consultation-inquiry__tel dt,
.top-consultation-inquiry__tel dd {
  display: table-cell;
  font-size: 1.5rem;
  line-height: 1.4;
}
.top-consultation-inquiry__tel dt {
  width: 6em;
  padding-right: 2.15rem;
  border-right: 1px solid #fff;
  box-sizing: content-box;
}
.top-consultation-inquiry__tel dd {
  padding-left: 2.05rem;
  padding-right: 2.9rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  vertical-align: bottom;
  line-height: 1;
}
.top-consultation-inquiry__tel dd a {
  color: #fff;
  font-size: 3.1rem;
  padding-left: 1rem;
}
.top-consultation-inquiry__address {
  letter-spacing: 0.05em;
}
.top-consultation-reserv__btn {
  width: 132px;
  display: block;
  padding: 1.3rem 3.3rem;
  background-color: #fff;
  border-radius: 6px;
  color: #f0841a;
  text-align: center;
}
.top-consultation-reserv__btn i {
  font-size: 2.7rem;
}
@media (hover: hover) {
  .top-consultation-reserv__btn:hover {
    color: #f0841a;
    opacity: 0.5;
  }
}

.top-topics {
  margin: 2.2rem auto 3.7rem;
  padding: 0 2.4rem;
  background-color: #fdefcd;
}
@media screen and (min-width: 1024px) {
  .top-topics {
    margin: 14rem auto 5.5rem;
    padding: 0 7rem;
    background-color: transparent;
  }
}
.top-topics-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
@media screen and (min-width: 1024px) {
  .top-topics-list {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4.2rem;
    grid-row-gap: 0;
  }
}
.top-topics-link {
  height: 100%;
  display: block;
  padding: 1.6rem 2rem;
  background-color: #ffd08a;
  text-align: center;
  color: #f0841a;
  border-radius: 6px;
  letter-spacing: 0.05em;
  line-height: 1;
}
@media screen and (min-width: 1024px) {
  .top-topics-link {
    padding: 3.3rem 3rem 4.9rem;
  }
}
.top-topics-link__icon {
  max-width: 84px;
  margin: 0 auto 1rem;
}
@media screen and (min-width: 1024px) {
  .top-topics-link__icon {
    max-width: 136px;
    margin-bottom: 3rem;
  }
}
.top-topics-link__text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 1024px) {
  .top-topics-link__text {
    font-size: 2rem;
    margin-bottom: 2.6rem;
  }
}
.top-topics-link__more {
  font-size: 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .top-topics-link__more {
    font-size: 1.4rem;
  }
}
.top-topics-link__more::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="%23f0841a"%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3cpath d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 2.2rem;
  height: 2.2rem;
  vertical-align: text-top;
}
@media screen and (min-width: 1024px) {
  .top-topics-link:hover {
    background-color: #f0841a;
    color: #fff;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .top-topics-link:hover .top-topics-link__more::after {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="%23fff"%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3cpath d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/%3e%3c/svg%3e');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.top-news {
  padding: 5rem 0 7rem;
  background: radial-gradient(circle at left 6px bottom 45px, #fdefcd 128px, transparent 129px) no-repeat;
}
@media screen and (min-width: 1024px) {
  .top-news {
    padding-bottom: 15.5rem;
    background: radial-gradient(circle at left 6px top 273px, #fdefcd 272px, transparent 273px) no-repeat;
  }
}
@media screen and (min-width: 1024px) {
  .top-news-inner {
    display: flex;
  }
}
.top-news-title {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .top-news-title {
    padding-right: 14vw;
  }
}
.top-news-list {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .top-news-list {
    margin-top: 4.4rem;
    flex: 1;
  }
}

.top-photo img {
  width: 100%;
  max-height: 511px;
  object-fit: cover;
}

.top-info {
  padding: 5.2rem 0 0;
  background: radial-gradient(circle at right 0 top 24px, #fdefcd 128px, transparent 129px) no-repeat;
}
@media screen and (min-width: 1024px) {
  .top-info {
    margin-bottom: 9.6rem;
    padding: 11rem 0 0;
    background: radial-gradient(circle at right 100px top 44px, #fdefcd 326px, transparent 327px) no-repeat;
  }
}
.top-info .content-title--en {
  margin-bottom: 3.5rem;
  letter-spacing: 0.08em;
}
@media screen and (min-width: 1024px) {
  .top-info .content-title--en {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .top-info-wrap {
    display: flex;
    flex-wrap: wrap;
    background: radial-gradient(circle at left 50px bottom 367px, #fdefcd 326px, transparent 327px) no-repeat;
  }
}
.top-info-photo {
  margin-right: 2.2rem;
}
@media screen and (min-width: 1024px) {
  .top-info-photo {
    width: calc(50% - 11.3rem);
    margin-right: 11.3rem;
  }
}
.top-info-photo img {
  border-radius: 0 2rem 2rem 0;
}
@media screen and (min-width: 1024px) {
  .top-info-photo img {
    height: auto;
    object-fit: cover;
    border-radius: 0 4rem 4rem 0;
    object-position: right;
  }
}

.top-about {
  padding-top: 6.5rem;
  background: radial-gradient(circle at left 6px top 45px, #fdefcd 128px, transparent 129px) no-repeat;
}
@media screen and (min-width: 1024px) {
  .top-about {
    width: 50%;
    padding-top: 2.4rem;
    background: none;
  }
}
@media screen and (min-width: 1024px) {
  .top-about .content-inner {
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .top-about__title {
    margin-bottom: 6.2rem;
  }
}
@media screen and (min-width: 768px) {
  .top-about dl.dl-list {
    display: block;
  }
}
.top-about dl.dl-list dt {
  font-size: 1.6rem;
  padding-bottom: 1.8rem;
}
.top-about dl.dl-list dt:nth-of-type(n + 2) {
  margin-top: 2.8rem;
}
@media screen and (min-width: 768px) {
  .top-about dl.dl-list dt {
    font-size: 1.8rem;
    border: none;
    padding-bottom: 1rem;
  }
  .top-about dl.dl-list dt:nth-of-type(n + 2) {
    margin-top: 2.6rem;
  }
}
.top-about dl.dl-list dd {
  border: none;
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .top-about dl.dl-list dd {
    padding-left: 0;
  }
  .top-about dl.dl-list dd:nth-of-type(n + 2) {
    margin-top: 0;
  }
}
.top-about dl.dl-list .txt-tel {
  margin: 0.6rem auto;
}
@media screen and (min-width: 1024px) {
  .top-about dl.dl-list .txt-tel {
    margin: 0;
    padding-bottom: 0.5rem;
    letter-spacing: 0.02em;
  }
}
.top-about .consultation-time {
  margin-top: 1.6rem;
  padding: 0;
  box-shadow: none;
  color: #333;
}
@media screen and (min-width: 1024px) {
  .top-about .consultation-time {
    margin-top: 0.6rem;
  }
}
.top-about .consultation-time__table tr {
  border-bottom-color: #ddd;
}
@media screen and (min-width: 1024px) {
  .top-about .consultation-time__table th,
  .top-about .consultation-time__table td {
    padding: 1.15rem 0;
  }
  .top-about .consultation-time__table th {
    padding-left: 2px;
  }
}
@media screen and (min-width: 1024px) {
  .top-about .consultation-time__closed {
    margin-top: 1.2rem;
  }
}

.top-access {
  padding-top: 2.4rem;
  padding-bottom: 6.6rem;
  background: radial-gradient(circle at right 0 bottom 129px, #fdefcd 128px, transparent 129px) no-repeat;
}
@media screen and (min-width: 1024px) {
  .top-access {
    padding-bottom: 17.4rem;
    background: radial-gradient(circle at right 75px bottom 401px, #fdefcd 326px, transparent 327px) no-repeat;
  }
}
.top-access iframe {
  width: 100%;
  margin-bottom: 1.4rem;
  aspect-ratio: 3/3.22;
  border-radius: 12px;
}
@media screen and (min-width: 1024px) {
  .top-access iframe {
    aspect-ratio: 16/6.73;
  }
}
@media screen and (min-width: 1024px) {
  .top-access-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .top-access-foot .btn-wrap {
    margin-top: 0;
  }
}
.top-access-foot .btn-wrap .btn {
  min-width: 184px;
  padding: 1rem;
}
.top-access .content-title--en {
  margin-bottom: 2rem;
}
@media screen and (min-width: 1024px) {
  .top-access .content-title--en {
    margin-bottom: 6rem;
  }
}

.top-banner {
  padding: 3.8rem 0 3.3rem;
  background-color: #fafafa;
}
@media screen and (min-width: 1024px) {
  .top-banner {
    padding: 8.6rem 0;
  }
}
.top-banner-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
@media screen and (min-width: 1024px) {
  .top-banner-list {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    width: 1000px;
    margin: 0 auto;
  }
}