@charset 'UTF-8';

@use './icon-svg' as *;

$material-icon-font-family: 'Material Icons';

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

// 種類を追加したい場合：'使用する名前': svg の変数名;
//
$svg-icon-types: (
  'arrow-up': $arrow-up,
  'arrow-down': $arrow-down,
  'arrow-left': $arrow-left,
  'arrow-right': $arrow-right,
  'chevron-up': $chevron-up,
  'chevron-down': $chevron-down,
  'chevron-left': $chevron-left,
  'chevron-right': $chevron-right,
  'caret-down': $caret-down,
  'caret-left': $caret-left,
  'caret-right': $caret-right,
  'plus': $plus,
  'minus': $minus,
  'circle-plus': $circle-plus,
  'circle-minus': $circle-minus,
  'pdf': $pdf,
  'document': $document,
  'phone': $phone,
  'circle-phone': $circle-phone,
  'smart-phone': $smart-phone,
  'circle-smart-phone': $circle-smart-phone,
  'fax': $fax,
  'mail': $mail,
  'open-new-window': $open-new-window,
  'calendar': $calendar,
  'search': $search,
  'caution': $caution,
  'home': $home,
  'map': $map,
  'map-pin': $map-pin,
  'tree': $tree,
  'quiz': $quiz,
  'checkbox': $checkbox,
  'checkbox-checked': $checkbox-checked,
  'radio': $radio,
  'radio-checked': $radio-checked,
  'remove': $remove,
  'access': $access,
  'menu': $menu,
  'reserve': $reserve,
) !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29')) !default;
