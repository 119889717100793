@charset 'UTF-8';

@use './color' as *;
@use 'sass:math';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Raleway:wght@700&family=Roboto:wght@500&family=Shippori+Mincho:wght@500&display=swap');

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1226px,
) !default;

// Container
$container-max-width: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 1024px,
  xl: 1226px,
) !default;

// Base font family
$base-font-family: 'Noto Sans JP', sans-serif;
$base-font-family-en: 'Roboto', sans-serif;
$base-font-family-en-raleway: 'Raleway', sans-serif;

// Base text color
$base-text-color: $gray-dark !default;

// Base background color
$base-background-color: $white !default;

// Base font size
$base-font-size: 1rem !default;

// root font size
$root-font-size: (
  xs: 14px,
  sm: 14px,
  md: 14px,
  lg: 16px,
  xl: 16px,
) !default;

$font-size-percentage: (
  'xsmall': 62.5%,
  'small': 75%,
  'medium': 87.5%,
  'large': 112.5%,
  'xlarge': 125%,
  'xxlarge': 150%,
  'exlarge': 200%,
) !default;

$base-line-height: 1.75 !default;

$base-letter-spacing: 0.05em;

// Heading
$h1-font-size: $base-font-size * 2.8 !default;
$h2-font-size: $base-font-size * 2.2 !default;
$h3-font-size: $base-font-size * 2 !default;
$h4-font-size: $base-font-size * 1.8 !default;
$h5-font-size: $base-font-size * 1.4 !default;
$h6-font-size: $base-font-size * 1.4 !default;

$h1-margin-top: 3rem !default;
$h2-margin-top: 2.4rem !default;
$h3-margin-top: 2rem !default;
$h4-margin-top: 1.6rem !default;
$h5-margin-top: 1rem !default;
$h6-margin-top: 1rem !default;

$h1-margin-bottom: 8rem !default;
$h2-margin-bottom: 5.8rem !default;
$h3-margin-bottom: 3.2rem !default;
$h4-margin-bottom: 3rem !default;
$h5-margin-bottom: 2rem !default;
$h6-margin-bottom: 2rem !default;

$heading-line-height: 1.8 !default;

// Paragraph
// .lead
$lead-letter-spacing: 0.05em !default;
$lead-line-height: 1.8 !default;
$lead-top-spacer: 1em !default;

// Spacer
$base-spacer: 1rem !default;

// Base gutter
$base-gutter: 1rem !default;

$base-gutter-xs: $base-gutter * 2 !default;
$base-gutter-sm: $base-gutter * 2 !default;
$base-gutter-md: $base-gutter * 2 !default;
$base-gutter-lg: $base-gutter * 2 !default;
$base-gutter-xl: $base-gutter * 7 !default;

$base-gutters: (
  xs: $base-gutter-xs,
  sm: $base-gutter-sm,
  md: $base-gutter-md,
  lg: $base-gutter-lg,
  xl: $base-gutter-xl,
) !default;

// Base side padding
$base-side-padding-xs: $base-gutter-xs !default;
$base-side-padding-sm: $base-gutter-sm !default;
$base-side-padding-md: $base-gutter-md !default;
$base-side-padding-lg: $base-gutter-lg !default;
$base-side-padding-xl: $base-gutter-xl !default;

$base-side-padding: (
  xs: $base-side-padding-xs,
  sm: $base-side-padding-sm,
  md: $base-side-padding-md,
  lg: $base-side-padding-lg,
  xl: $base-side-padding-xl,
) !default;

// header height
$header-height-xs: 44px !default;
$header-height-sm: 44px !default;
$header-height-md: 44px !default;
$header-height-lg: 80px !default;
$header-height-xl: 80px !default;

$header-height: (
  xs: $header-height-xs,
  sm: $header-height-sm,
  md: $header-height-md,
  lg: $header-height-lg,
  xl: $header-height-xl,
) !default;

// Section block
$section-block-gutter-xs: 6.4rem !default;
$section-block-gutter-sm: 6.4rem !default;
$section-block-gutter-md: 6.4rem !default;
$section-block-gutter-lg: 8rem !default;
$section-block-gutter-xl: 8rem !default;

$section-block-gutters: (
  xs: $section-block-gutter-xs,
  sm: $section-block-gutter-sm,
  md: $section-block-gutter-md,
  lg: $section-block-gutter-lg,
  xl: $section-block-gutter-xl,
) !default;

// Grid
$grid-columns: 12 !default;

$grid-gutter-xs: 16px !default;
$grid-gutter-sm: 16px !default;
$grid-gutter-md: 20px !default;
$grid-gutter-lg: 24px !default;
$grid-gutter-xl: 24px !default;

$grid-gutters: (
  xs: $grid-gutter-xs,
  sm: $grid-gutter-sm,
  md: $grid-gutter-md,
  lg: $grid-gutter-lg,
  xl: $grid-gutter-xl,
) !default;

// Anchor links
$base-link-color: $base !default;
$base-link-decoration: none !default;
$base-link-hover-color: darken($base-link-color, 15%) !default;
$base-link-hover-decoration: none !default;

// Horizontal line
$hr-border: 1px solid $gray-400 !default;
$hr-y-gutter: 1rem !default;

// Label
$label-font-size: 0.75em;

$label-padding-y: (
  xs: 0.425em,
  sm: 0.425em,
  md: 0.5em,
  lg: 0.4em,
  xl: 0.4em,
) !default;

$label-padding-x: (
  xs: 0.6em,
  sm: 0.6em,
  md: 0.6em,
  lg: 0.6em,
  xl: 0.6em,
) !default;

// 角丸
$label-border-radius: 0px !default;

// アウトライン時のボーダーの太さ
$label-border-width: 1px !default;

// background color
$label-background-colors: (
  'base': $base,
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $primary,
  'quaternary': $quaternary,
  'quinary': $quinary,
  'senary': $senary,
  'septenary': $septenary,
  'octonary': $octonary,
  'novenary': $novenary,
  'decenary': $decenary,
) !default;

// text color
$label-colors: (
  'base': $white,
  'primary': $white,
  'secondary': $white,
  'tertiary': $white,
  'quaternary': $white,
  'quinary': $white,
  'senary': $white,
  'septenary': $white,
  'octonary': $white,
  'novenary': $white,
  'decenary': $white,
) !default;

// Table
$table-border-color: $gray-300 !default;
$table-th-background-color: $white !default;
$table-td-background-color: $white !default;

// Base transition
$base-transition: 0.3s ease-out;

// Base box-shadw
$base-box-shadow: 0 0.3rem 1.4rem rgba($primary, 0.15) !default;
$base-box-shadow-sm: 0 0.125rem 0.25rem rgba($primary, 0.075) !default;
$base-box-shadow-lg: 0 1rem 3rem rgba($primary, 0.175) !default;

// breakpoint ごとの比率
$scale-sm: math.div(map-get($root-font-size, sm), map-get($root-font-size, xs));
$scale-md: math.div(map-get($root-font-size, md), map-get($root-font-size, xs));
$scale-lg: math.div(map-get($root-font-size, lg), map-get($root-font-size, xs));
$scale-xl: math.div(map-get($root-font-size, xl), map-get($root-font-size, xs));

$scales: (
  sm: $scale-sm,
  md: $scale-md,
  lg: $scale-lg,
  xl: $scale-xl,
) !default;

// z-index
$z-index-header: 5 !default;
$z-index-nav: 3 !default;
$z-index-main: 0 !default;
$z-index-footer: 1 !default;
$z-index-pagetop: 4 !default;
$z-index-loading: 999 !default;
